import Footer from '../Footer/Footer'
import Nav from '../nav/nav'

export const EventosReligiosos = () => {
  return (
    <div>
      <Nav />

      <h1>somos eventos religiosos</h1>
      <Footer />
    </div>
  )
}
