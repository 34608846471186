import Footer from '../Footer/Footer'
import Nav from '../nav/nav'

export const Graduaciones = () => {
  return (
    <div>
      <Nav />
      <h1>Somos el componente Graduaciones</h1>
      <Footer />
    </div>
  )
}
