import Footer from '../Footer/Footer'
import Nav from '../nav/nav'

export const Recuerdos = () => {
  return (
    <div>
      <Nav />
      <h1>Somos los recuerdos</h1>

      <Footer />
    </div>
  )
}
