import Footer from '../Footer/Footer'
import Nav from '../nav/nav'
import '../styles/styles.css'

export const Quniceañeras = () => {
  return (
    <div>
      <Nav />
      <h1>Somos el componente de las quinceañeras</h1>
      <Footer />
    </div>
  )
}
