import Footer from '../Footer/Footer'
import Nav from '../nav/nav'

const Gestantes = () => {
  return (
    <div>
      <Nav />
      <h1>Somos el componente de Gestantes</h1>
      <Footer />
    </div>
  )
}

export default Gestantes
